//  libraries
import React from 'react'

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'
import { RequestAQuote } from '../containers/RequestAQuote'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'
//  containers

const LayoutContainer = styled(Layout)`
  display: grid;
  grid-template-rows: 1fr auto;
`

const RequestAQuotePage = () => {
  return (
    <LayoutContainer withoutScrollSnap>
      <SEO
        title="Request a Quote| Edge Logistics"
        description="Digital freight broker with instant capacity for shippers. Free demo of CAPACITY, our freight management and trucking app. Carriers, join us. Get the Edge!"
        keywords="Freight broker,digital freight broker,trucking app,freight management"
      />
      <RequestAQuote />
      <ToastContainer />
    </LayoutContainer>
  )
}

export default RequestAQuotePage
