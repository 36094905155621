import React, { useRef } from 'react'
import { useSearchableData } from '../../hooks/useSearchableData'
import { Controller } from 'react-hook-form'
import { Autocomplete } from '.'

export const EquipmentField = ({
  name,
  control,
  rules,
  register,
  required
}) => {
  const options = useRef({
    time: 700,
    limit: 30,
    requestOptions: {
      externalUrl: process.env.GATSBY_COMMON_URL,
    },
  })
  const {
    data = [],
    loading,
    setKeyword,
    getNextPage,
    noMore
  } = useSearchableData('/equipment-types', 'description', options.current)
  const handleSetKeyword = (_, value) => {
    setKeyword(value)
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          register={register}
          required={required}
          onChange={onChange}
          name={name}
          options={data}
          loading={loading}
          asyncFilter={handleSetKeyword}
          getMore={getNextPage}
          error=""
          noMore={noMore}
          disabled={false}
          selectedValue={value}
          uniqueKeyName="equipment_code"
          optionKeyName="description"
          clear={() => null}
        />
      )}
    />
  )
}
