import styled from 'styled-components'
import { NewInput } from '../../components/NewInput'
import { Textarea as TextareaUI } from '../../components/Textarea'
import { CustomDatePicker as DatePickerUI } from '../../components/DatePicker'
import background from '../../assets/image/requestQuote/background_request_quote_new.jpg'

export const PageContainer = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 5% 10%;
`

export const ContentContainer = styled.div`
  max-width: 135rem;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (min-width: 850px) {
    padding: 0 0 0 15rem;
    align-items: flex-start;
  }
`

export const RequestQuoteTitle = styled.div`
  background-color: #147efb;
  border-radius: 20px;
  padding: 10px 0px;
  max-width: 540px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  h1 {
    margin: 0;
    font-weight: 700;
    font-size: 2.5rem;
    color: white;
  }
`

export const FormContainer = styled.form`
  background-color: white;
  max-width: 540px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 40px;
  color: #147efb;
  font-size: 1.4rem;
  overflow: hidden auto;
  transition: all 0.3s ease;
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative;
  
  .steps {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    color: #147efb;
    font-weight: 600;
  }
  
  .form-inputs {
    height: 100%;
    overflow: hidden auto;
    padding: 30px 35px 15px;
  }
  
  .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    padding: 30px 35px;
  }
  
  .step-button {
    min-width: unset;
    width: 100%;
    font-family: 'Poppins';
    background: transparent;
    border: 4px solid #147efb;
    font-weight: 500;
    font-size: 1.6rem;
    color: #147efb;

    &:hover {
      background: #147efb;
      color: #ffffff;
      border: 4px solid #147efb;
    }
  }
  
  .submit-button {
    min-width: unset;
    width: 100%;
    font-family: 'Poppins';
    background: #147efb;
    border: 4px solid #147efb;
    font-weight: 500;
    font-size: 1.6rem;
    color: #ffffff;

    &:hover {
      background: #147efb;
      color: #ffffff;
      border: 4px solid #147efb;
    }
  }
`

export const FormSectionContainer = styled.div``

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  p {
    margin: 0;
  }
  .autocomplete-input {
    background-color: #f3f7f9;
    border: solid 1px #cbd6e2;
    border-radius: 5px;
  }
`

export const TwoColumnsInputContainer = styled.div`
  @media (min-width: 670px) {
    display: flex;
    justify-content: space-between;
    
    div {
      max-width: 220px;
      width: 100%;
    }
  }
`

export const FormSectionLabel = styled.p`
  font-weight: bold;
  margin: 0;
  margin-top: 15px;
`

export const Input = styled(NewInput)`
  background-color: #f3f7f9;
  border: solid 1px #cbd6e2;
  border-radius: 5px;
`

export const Textarea = styled(TextareaUI)`
  background-color: #f3f7f9;
  border: solid 1px #cbd6e2;
  border-radius: 5px;
`

export const Datepicker = styled(DatePickerUI)`
  background-color: #f3f7f9;
  border: solid 1px #cbd6e2;
  border-radius: 5px;
`
export const ErrorSpan = styled.span`
  color: #f2545b;
`
