export const getTextCapitalized = (text) => {
    let temp = ''
    if (text) {
      text.split(' ').forEach((item) => {
        temp += ' ' + item.charAt(0).toUpperCase() + item.toLowerCase().slice(1)
      })
    }
  
    return temp.length ? temp.slice(1, temp.length) : temp
  }

  export const getLocationText = (location, showCountry) => {
    let locationText = '- -'
    if (location?.city) {
      locationText = getTextCapitalized(location.city)
        .concat(`, ${location.state_id}${showCountry ? ' - ' + location.country : ''}`)
        .trim()
    } else if (location?.state) {
      locationText = getTextCapitalized(location.state)
        .concat(` (${location.state_id})${showCountry ? ' - ' + location.country : ''}`)
        .trim()
    }
    return locationText
  }