import React, { useState, useEffect } from 'react'
import { CommodityField } from '../../components/Autocomplete/CommodityField'
import { EquipmentField } from '../../components/Autocomplete/EquipmentField'
import DateJsUtils from '@date-io/dayjs'
import Fade from 'react-reveal/Fade';
import { LocationField } from '../../components/Autocomplete/LocationField'
import {
  Input,
  PageContainer,
  FormContainer,
  FormSectionContainer,
  FormSectionLabel,
  InputContainer,
  TwoColumnsInputContainer,
  Textarea,
  ContentContainer,
  RequestQuoteTitle,
  Datepicker,
  ErrorSpan
} from './styles'
import Button from '../../components/CustomButton'
import { useForm } from 'react-hook-form'
import { Fetch } from '../../utils/fetch'
import { saveSecureStorage } from '../../utils/storage'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { toast } from 'react-toastify'
import { navigate } from 'gatsby'

export const RequestAQuote = () => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
    clearErrors
  } = useForm()
  const pages = 2;
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false)
  const onSubmit = async (data) => {
    const handleSubmit = Fetch('post', '/request-quote', {
      body: { ...data },
      authorization: ''
    })

    toast.promise(handleSubmit, {
      pending: {
        render() {
          setLoading(true)
          return 'We are sending your request'
        }
      },
      success: {
        render() {
          reset()
          clearErrors()
          setLoading(false)
          navigate('/thank-you')
        }
      },
      error: {
        render() {
          setLoading(false)
          return 'Something went wrong. Please try again or contact support'
        }
      }
    })
  }
  const [shipperDate, setShipperDate] = useState('')
  const [receiverDate, setReceiverDate] = useState('')
  useEffect(() => {
    const loginUser = async () => {
      try {
        const result = await Fetch('post', '', {
          body: {
            email: process.env.GATSBY_USER,
            password: process.env.GATSBY_PASSWORD
          },
          externalUrl: process.env.GATSBY_LOGIN_URL
        })
        saveSecureStorage({
          key: 'auth',
          value: result?.data?.access_token || null
        })
      } catch (error) {
        console.log(error)
      }
    }
    loginUser()
  }, [])
  useEffect(() => {
    register('shipper_date')
    register('receiver_date')
  }, [register])

  const nextStep = (add) => (e) => {
    e.preventDefault();
    setStep(prev => prev + add);
  }

  return (
    <MuiPickersUtilsProvider utils={DateJsUtils}>
      <PageContainer>
        <ContentContainer>
          <RequestQuoteTitle>
            <h1>Request a Quote</h1>
          </RequestQuoteTitle>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <section className='form-inputs'>
              {
                step === 1 && (
                  <Fade right>
                    <FormSectionLabel>Contact Information</FormSectionLabel>
                    <FormSectionContainer>
                      <TwoColumnsInputContainer>
                        <InputContainer>
                          <p>
                            First Name<ErrorSpan>*</ErrorSpan>
                          </p>
                          <Input {...register('first_name', { required: true })} />
                          {errors.first_name && (
                            <ErrorSpan>This field is required</ErrorSpan>
                          )}
                        </InputContainer>
                        <InputContainer>
                          <p>
                            Last Name<ErrorSpan>*</ErrorSpan>
                          </p>
                          <Input {...register('last_name', { required: true })} />
                          {errors.last_name && (
                            <ErrorSpan>This field is required</ErrorSpan>
                          )}
                        </InputContainer>
                      </TwoColumnsInputContainer>
                      <InputContainer>
                        <p>
                          Email<ErrorSpan>*</ErrorSpan>
                        </p>
                        <Input {...register('email', { required: true })} />
                        {errors.email && <ErrorSpan>This field is required</ErrorSpan>}
                      </InputContainer>
                      <InputContainer>
                        <p>
                          Phone<ErrorSpan>*</ErrorSpan>
                        </p>
                        <Input {...register('phone', { required: true })} />
                        {errors.phone && <ErrorSpan>This field is required</ErrorSpan>}
                      </InputContainer>
                      <InputContainer>
                        <p>
                          Company<ErrorSpan>*</ErrorSpan>
                        </p>
                        <Input {...register('company', { required: true })} />
                        {errors.company && (
                          <ErrorSpan>This field is required</ErrorSpan>
                        )}
                      </InputContainer>
                    </FormSectionContainer>
                    <FormSectionLabel>Shipper</FormSectionLabel>
                    <FormSectionContainer>
                      <TwoColumnsInputContainer>
                        <InputContainer>
                          <p>
                            Location<ErrorSpan>*</ErrorSpan>
                          </p>
                          <LocationField
                            register={register}
                            required={true}
                            name="shipper_location"
                            control={control}
                            errors={errors}
                            rules={{ required: true }}
                          />
                          {errors.shipper_location && (
                            <ErrorSpan>This field is required</ErrorSpan>
                          )}
                        </InputContainer>
                        <InputContainer>
                          <p>
                            Date<ErrorSpan>*</ErrorSpan>
                          </p>
                          <Datepicker
                            {...register('shipper_date', { required: true })}
                            value={shipperDate}
                            onChange={(date) => {
                              const parsedDate = date.toISOString().split('T')[0]
                              setShipperDate(date)
                              setValue('shipper_date', parsedDate, {
                                shouldValidate: true,
                                shouldDirty: true
                              })
                            }}
                          />
                          {errors.shipper_date && (
                            <ErrorSpan>This field is required</ErrorSpan>
                          )}
                        </InputContainer>
                      </TwoColumnsInputContainer>
                    </FormSectionContainer>
                  </Fade>
                )
              }
              {
                step === 2 && (
                  <Fade right>
                    <FormSectionLabel>Receiver</FormSectionLabel>
                    <FormSectionContainer>
                      <TwoColumnsInputContainer>
                        <InputContainer>
                          <p>
                            Location<ErrorSpan>*</ErrorSpan>
                          </p>
                          <LocationField
                            register={register}
                            required={true}
                            name="receiver_location"
                            control={control}
                            errors={errors}
                            rules={{ required: true }}
                          />
                          {errors.receiver_location && (
                            <ErrorSpan>This field is required</ErrorSpan>
                          )}
                        </InputContainer>
                        <InputContainer>
                          <p>
                            Date<ErrorSpan>*</ErrorSpan>
                          </p>
                          <Datepicker
                            {...register('receiver_date', { required: true })}
                            value={receiverDate}
                            onChange={(date) => {
                              const parsedDate = date.toISOString().split('T')[0]
                              setReceiverDate(date)
                              setValue('receiver_date', parsedDate, {
                                shouldValidate: true,
                                shouldDirty: true
                              })
                            }}
                          />
                          {errors.receiver_date && (
                            <ErrorSpan>This field is required</ErrorSpan>
                          )}
                        </InputContainer>
                      </TwoColumnsInputContainer>
                    </FormSectionContainer>
                    <FormSectionContainer>
                      <TwoColumnsInputContainer>
                        <InputContainer>
                          <p>
                            Equipment Type<ErrorSpan>*</ErrorSpan>
                          </p>
                          <EquipmentField
                            register={register}
                            required={true}
                            name="equipment_type"
                            control={control}
                            errors={errors}
                            rules={{ required: true }}
                          />
                          {errors.equipment_type && (
                            <ErrorSpan>This field is required</ErrorSpan>
                          )}
                        </InputContainer>
                        <InputContainer>
                          <p>
                            Weight<ErrorSpan>*</ErrorSpan>
                          </p>
                          <Input {...register('weight', { required: true })} />
                          {errors.weight && (
                            <ErrorSpan>This field is required</ErrorSpan>
                          )}
                        </InputContainer>
                      </TwoColumnsInputContainer>
                    </FormSectionContainer>
                    <FormSectionContainer>
                      <InputContainer>
                        <p>
                          Commodity<ErrorSpan>*</ErrorSpan>
                        </p>
                        <CommodityField
                          register={register}
                          required={true}
                          name="commodity"
                          control={control}
                          errors={errors}
                          rules={{ required: true }}
                        />
                        {errors.commodity && (
                          <ErrorSpan>This field is required</ErrorSpan>
                        )}
                      </InputContainer>
                    </FormSectionContainer>
                    <FormSectionContainer>
                      <InputContainer>
                        <p>Message</p>
                        <Textarea
                          defaultValue=""
                          {...register('message', {})}
                          inputType={'textarea'}
                        />
                      </InputContainer>
                    </FormSectionContainer>
                  </Fade>
                )
              }
            </section>
            <section className="buttons-container">
              <span className='steps'>{step} / {pages}</span>
              {
                step > 1 ? (
                  <Button
                    className="step-button"
                    text="Previous"
                    onClick={ nextStep( -1 ) }
                    disabled={ loading }
                  />
                ) : <div />
              }
              {
                step < pages ? (
                  <Button
                    className="step-button"
                    text="Next"
                    onClick={ nextStep( 1 ) }
                    disabled={ loading }
                  />
                ) : (
                  <Button
                    className="submit-button"
                    text="Submit"
                    onClick={ () => null }
                    disabled={ loading }
                  />
                )
              }
            </section>
          </FormContainer>
        </ContentContainer>
      </PageContainer>
    </MuiPickersUtilsProvider>
  )
}
